import { atom, useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import React, { useRef } from 'react';

import {
  addPoints,
  MotionPoint,
  MotionPointToPoint,
  Point,
  setMotionPoint,
  useMotionPoint,
} from '../../lib/point';
import useDrag from '../../lib/useDrag';
import { windowAtomFamily } from '../../state/atoms';
import { ResizeCorners } from './Resize';
import { Chrome, Content, Title, TitleActions, TitleButton } from './Styled';
export { TitleButton } from './Styled';

export enum WindowType {
  DEFAULT = 'DEFAULT',
  DOCKED = 'DOCKED',
  CHROMELESS = 'CHROMELESS',
}

type WindowProps = {
  type?: WindowType;
  id: string;
  title: string;
  position: Point;
  size: Point;
  isSelected: boolean;
  select?: () => void;
  actions?: React.ReactNode[];
};

export default function WindowComponent({
  children,
  ...props
}: React.PropsWithChildren<
  DefaultWindowProps | ChromelessWindowProps | DockedWindowProps
>) {
  if (props.type === WindowType.CHROMELESS) {
    return <ChromelessWindow {...props}>{children}</ChromelessWindow>;
  }
  if (props.type === WindowType.DOCKED) {
    return <DockedWindow {...props}>{children}</DockedWindow>;
  }
  return <DefaultWindow {...props}>{children}</DefaultWindow>;
}

type DefaultWindowProps = WindowProps & {
  type?: WindowType.DEFAULT;
};

function DefaultWindow({
  id,
  title,
  position,
  size,
  children,
  isSelected,
  select,
  actions,
}: React.PropsWithChildren<DefaultWindowProps>) {
  const [motionPosition, motionSize] = useWindowMotionPoints(position, size);
  const [draggableRef, isDragging] = useDragWindow(id, motionPosition);

  return (
    <Chrome
      style={{
        x: motionPosition.x,
        y: motionPosition.y,
        width: motionSize.x,
        height: motionSize.y,
      }}
      css={{
        width: size.x,
        height: size.y === 0 ? 'auto' : size.y,
        cursor: isDragging ? 'grabbing' : 'auto',
        borderColor: isSelected ? '$blue' : 'auto',
      }}
      onPointerDown={select}>
      <Title ref={draggableRef}>
        {actions && actions[0] && (
          <TitleActions side="left">{actions[0]}</TitleActions>
        )}
        {title}
        {actions && actions[1] && (
          <TitleActions side="right">{actions[1]}</TitleActions>
        )}
      </Title>
      <Content>{children}</Content>
      <ResizeCorners id={id} position={motionPosition} size={motionSize} />
    </Chrome>
  );
}

type ChromelessWindowProps = WindowProps & {
  type: WindowType.CHROMELESS;
};

function ChromelessWindow({
  id,
  position,
  size,
  isSelected,
  select,
  title,
  children,
}: React.PropsWithChildren<ChromelessWindowProps>) {
  const [motionPosition, motionSize] = useWindowMotionPoints(position, size);
  const [draggableRef, isDragging] = useDragWindow(id, motionPosition);

  return (
    <Chrome
      isTransparent
      style={{
        x: motionPosition.x,
        y: motionPosition.y,
        width: motionSize.x,
        height: size.y === 0 ? 'auto' : motionSize.y,
      }}
      css={{
        width: size.x,
        height: size.y === 0 ? 'auto' : size.y,
        cursor: isDragging ? 'grabbing' : 'auto',
      }}
      isSelected={isSelected}
      onPointerDown={select}>
      <Title isTransparent ref={draggableRef}>
        {title}
      </Title>
      <Content>{children}</Content>
      <ResizeCorners
        id={id}
        position={motionPosition}
        size={motionSize}
        disabled
      />
    </Chrome>
  );
}

type DockedWindowProps = Pick<
  WindowProps,
  'title' | 'isSelected' | 'select' | 'actions'
> & {
  type: WindowType.DOCKED;
};

const dockedWindowPositionAtom = atom(0);

function DockedWindow({
  title,
  isSelected,
  select,
  children,
  actions,
}: React.PropsWithChildren<DockedWindowProps>) {
  const [counter, setCounter] = useAtom(dockedWindowPositionAtom);
  const side = counter % 2;
  return (
    <Chrome
      css={{
        borderColor: isSelected ? '$blue' : '$border',
        zIndex: isSelected ? '$dockedWindowSelected' : '$dockedWindow',
      }}
      onPointerDown={select}
      isDocked
      docked={side}>
      <Title>
        {actions && actions[0] && (
          <TitleActions side="left">{actions[0]}</TitleActions>
        )}
        {title}
        <TitleActions side="right">
          {actions && actions[1]}
          <TitleButton onClick={() => setCounter((v) => v + 1)}>
            Flip
          </TitleButton>
        </TitleActions>
      </Title>
      <Content>{children}</Content>
    </Chrome>
  );
}

function useWindowMotionPoints(position: Point, size: Point) {
  const motionPosition = useMotionPoint(position);
  const motionSize = useMotionPoint(size);

  return [motionPosition, motionSize] as const;
}

function useDragWindow(id: string, position: MotionPoint) {
  const draggableRef = useRef<HTMLDivElement>(null);
  const setWindow = useUpdateAtom(windowAtomFamily(id));
  const [isDragging] = useDrag(
    () =>
      setWindow((window) => {
        window.position = MotionPointToPoint(position);
      }),
    (offset) => {
      setMotionPoint(position, addPoints(position, offset));
    },
    draggableRef,
  );

  return [draggableRef, isDragging] as const;
}
