import * as Toolbar from '@radix-ui/react-toolbar';
import { atom, useAtom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import React from 'react';

import {
  documentsAtom,
  selectedDocumentIdAtom,
  selectedWindowIdAtom,
  selectedWorkspaceIdAtom,
  windowsAtom,
  workspaceAtom,
  workspacesAtom,
} from '../state/atoms';
import { createNewDocument } from '../state/data';
import { styled } from '../stitches.config';

export default function Navigation() {
  return (
    <Container>
      <WorkspaceSelector />
      <DocumentSelector />
    </Container>
  );
}

const Container = styled('nav', {
  card: '$background',
});

function WorkspaceSelector() {
  const workspaces = useAtomValue(workspacesAtom);
  const [selection, setSelection] = useAtom(selectedWorkspaceIdAtom);

  return (
    <>
      <label htmlFor="workspace" className="visually-hidden">
        Workspace
      </label>
      <select
        name="workspace"
        id="workspace"
        value={selection}
        onChange={(e) => setSelection(e.target.value)}>
        {Object.values(workspaces).map(({ id, title }) => (
          <option value={id} key={id}>
            {title}
          </option>
        ))}
      </select>
    </>
  );
}

const selectWindowForDocumentAtom = atom(null, (get, set, id: string) => {
  const windows = get(windowsAtom);
  const selectedWindow = Object.values(windows).find(
    (w) => w.documentId === id && w.type === 'RENDERER',
  );
  set(selectedWindowIdAtom, selectedWindow!.id);

  const editorW = window.innerWidth * 0.25; // editor is 25vw

  const pos = selectedWindow!.position;
  const size = selectedWindow!.size;
  const toleranceX = size.x / 2; // if a window is on screen "enough" we dont scroll
  const toleranceY = size.y > 0 ? size.y / 2 : 0;
  const windowInViewport =
    pos.x > window.scrollX - toleranceX + editorW &&
    pos.y > window.scrollY - toleranceY &&
    pos.x + size.x <
      window.scrollX + window.innerWidth + toleranceX - editorW &&
    pos.y + size.y < window.scrollY + window.innerHeight + toleranceY;

  if (windowInViewport) {
    return;
  }

  window.scrollTo(
    pos.x - (window.innerWidth - editorW - size.x) / 2, // center on screen, FIXME: not truly centered
    pos.y - (window.innerHeight - size.y) / 2,
  );
});

function DocumentSelector() {
  const documents = useAtomValue(documentsAtom);
  const selectWindowForDocument = useUpdateAtom(selectWindowForDocumentAtom);
  const selectedId = useAtomValue(selectedDocumentIdAtom);
  const setWorkspace = useUpdateAtom(workspaceAtom);

  if (!documents) {
    return null;
  }

  return (
    <DocumentList orientation="vertical">
      {Object.values(documents).map(({ id, title }) => (
        <DocumentItem
          key={id}
          onClick={() => selectWindowForDocument(id)}
          selected={id === selectedId}>
          {title}
        </DocumentItem>
      ))}
      <DocumentItem
        onClick={() => {
          const title = window.prompt('Name this document', '');
          if (title === null) {
            return;
          }
          setWorkspace((workspace) => {
            const [document, windows] = createNewDocument(title);
            workspace.documents[document.id] = Object.assign({}, document);
            windows.forEach((window) => {
              workspace.windows[window.id] = Object.assign({}, window);
            });
          });
        }}>
        + New
      </DocumentItem>
    </DocumentList>
  );
}

const DocumentList = styled(Toolbar.Root, {
  display: 'grid',
  gridGap: '$1',
  justifyItems: 'start',
  padding: '$4 0 0',
});

const DocumentItem = styled(Toolbar.Button, {
  display: 'block',
  appearance: 'none',
  padding: '$1',
  borderRadius: '$2',
  background: 'none',
  cursor: 'pointer',
  fontSize: 'inherit',
  border: '.5px solid transparent',

  '&:hover': {
    background: '$beigeDark',
  },
  '&:active': {
    color: 'inherit',
  },
  variants: {
    selected: {
      true: {
        background: '$beigeDarker',
        borderColor: '$grey50',
      },
    },
  },
});
