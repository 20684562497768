"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DIGIT_OR_LETTER = exports.LETTER = exports.LOWERCASE_LETTER = exports.UPPERCASE_LETTER = exports.INTEGER = exports.DIGIT = exports.DOUBLE_QUOTE = exports.SINGLE_QUOTE = exports.SIGN = exports.PLUS = exports.MINUS = exports.DASH = exports.BACKSLASH = exports.PERIOD = exports.NEWLINE = exports.WHITESPACE = exports.TAB = exports.SPACE = exports.oneOrMoreOf = exports.zeroOrMoreOf = exports.optionally = exports.oneOf = exports.groupOf = exports.oneCharacterButNot = exports.oneCharacterOf = exports.create = void 0;
const create = (...args) => new RegExp(args.map((arg) => (arg instanceof RegExp ? arg.source : arg)).join(''));
exports.create = create;
const oneCharacterOf = (...args) => exports.create('[', ...args, ']');
exports.oneCharacterOf = oneCharacterOf;
const oneCharacterButNot = (...args) => exports.create('[^', ...args, ']');
exports.oneCharacterButNot = oneCharacterButNot;
const groupOf = (...args) => exports.create('(?:', ...args, ')');
exports.groupOf = groupOf;
const oneOf = (...args) => exports.groupOf(...args.flatMap((arg, i) => (i ? ['|', arg] : arg)));
exports.oneOf = oneOf;
const optionally = (fragment) => exports.create(exports.groupOf(fragment), '?');
exports.optionally = optionally;
const zeroOrMoreOf = (fragment) => exports.create(exports.groupOf(fragment), '*');
exports.zeroOrMoreOf = zeroOrMoreOf;
const oneOrMoreOf = (fragment) => exports.create(exports.groupOf(fragment), '+');
exports.oneOrMoreOf = oneOrMoreOf;
exports.SPACE = ' ';
exports.TAB = /\t/;
exports.WHITESPACE = exports.oneOrMoreOf(exports.oneCharacterOf(exports.SPACE, exports.TAB));
exports.NEWLINE = /\n/;
exports.PERIOD = /\./;
exports.BACKSLASH = /\\/;
exports.DASH = '-';
exports.MINUS = '-';
exports.PLUS = '+';
exports.SIGN = exports.oneCharacterOf(exports.MINUS, exports.PLUS);
exports.SINGLE_QUOTE = "'";
exports.DOUBLE_QUOTE = '"';
exports.DIGIT = exports.oneCharacterOf('0-9');
exports.INTEGER = exports.oneOf('0', exports.groupOf(exports.oneCharacterOf('1-9'), exports.zeroOrMoreOf(exports.DIGIT)));
exports.UPPERCASE_LETTER = exports.oneCharacterOf('A-Z');
exports.LOWERCASE_LETTER = exports.oneCharacterOf('a-z');
exports.LETTER = exports.oneCharacterOf('A-Za-z');
exports.DIGIT_OR_LETTER = exports.oneCharacterOf('0-9A-Za-z');
