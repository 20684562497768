import { lex } from '@symaphore/composition';
import React, { useEffect, useMemo } from 'react';
import { createEditor, Node, NodeEntry, Range, Text } from 'slate';
import { withHistory } from 'slate-history';
import {
  Editable,
  ReactEditor,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react';

import { AutocompleteStyleMenu } from '../slate/InlineMenu';
import { onCopy } from '../slate/onCopy';
import { onKeyDown } from '../slate/onKeyDown';
import { serialize } from '../slate/utils';
import { styled } from '../stitches.config';

interface Props {
  nodes: Node[];
  updateNodes: (nodes: Node[]) => void;
  isFocused?: boolean;
}

const SymaphoreEditor = ({ nodes, updateNodes, isFocused }: Props) => {
  const editor = useMemo(() => withReact(withHistory(createEditor())), []);

  useEffect(() => {
    if (isFocused) {
      ReactEditor.focus(editor); // FIXME: this doesnt work
    }
  }, [isFocused, editor]);

  return (
    <>
      <Slate editor={editor} value={nodes} onChange={updateNodes}>
        <AutocompleteStyleMenu content={serialize(nodes)} />
        <StyledEditable
          decorate={decorate}
          renderLeaf={renderLeaf}
          onCopy={(e: React.ClipboardEvent) => onCopy(e, editor)}
          onKeyDown={(e: React.KeyboardEvent) => onKeyDown(e, editor)}
        />
      </Slate>
    </>
  );
};

export default SymaphoreEditor;

const StyledEditable = styled(Editable, {
  lineHeight: '1.4',
  '.Identifier': { color: '$blue' },
  '.identifier': { color: '$blue' },
  '.keyword': { color: '$blue' },
  '.number': { color: '$rust' },
  '.string': { color: '$rust' },
});

const decorate = ([node, path]: NodeEntry): Array<Range> => {
  if (!Text.isText(node)) {
    return [];
  }
  const ranges: Array<Range> = [];
  lex(node.text).forEach((token) => {
    const start = token.offset;
    const end = start + token.value.length;
    ranges.push({
      className: token.type,
      anchor: { path, offset: start },
      focus: { path, offset: end },
    });
  });
  return ranges;
};

const renderLeaf = ({ attributes, children, leaf }: RenderLeafProps) => (
  <span {...attributes} className={leaf.className as string}>
    {children}
  </span>
);
