"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const icons = __importStar(require("react-feather"));
const defaultProps_1 = require("../utils/defaultProps");
const displayName_1 = require("../utils/displayName");
const pipe_1 = require("../utils/pipe");
const spread_1 = require("../utils/spread");
// Need to use module.exports so we can automate renaming these, ES6 exports are
// static but we can dynamically create CJS exports
module.exports = lodash_1.fromPairs(lodash_1.toPairs(icons).map(([name, Component]) => [
    `${name}Icon`,
    pipe_1.pipe(displayName_1.displayName(`${name}Icon`), spread_1.spread('props'), defaultProps_1.defaultProps({ color: 'currentColor', size: '1em' }))(Component),
]));
