import { Provider as JotaiProvider } from 'jotai';
import React from 'react';

import Workspace from './Workspace';

const App = () => (
  <JotaiProvider>
    <Workspace />
  </JotaiProvider>
);

export default App;
