// adapted from https://github.com/paulshen/css-editor/blob/main/src/CSSData.ts
import { camelCase } from 'lodash';
import cssJson from 'vscode-web-custom-data/data/browsers.css-data.json';

import { AutocompleteItem } from './AutocompleteStyleMenu';

export function completePropertyName(query: string): AutocompleteItem[] {
  const results: AutocompleteItem[] = [];
  cssJson.properties.forEach(({ name, description, relevance }) => {
    if (name.startsWith(query)) {
      results.push({ name, description, relevance });
    } else if (camelCase(name).startsWith(query)) {
      results.push({ name: camelCase(name), description, relevance });
    }
  });
  return results;
}

export function isValidProperty(propertyName: string): boolean {
  return cssJson.properties.some(({ name }) => name === propertyName);
}

export function completePropertyValue(
  query: string,
  propertyName: string,
): AutocompleteItem[] {
  const values = getValidPropertyValues(propertyName);
  if (!values) {
    return [];
  }
  if (query.length === 0) {
    return values;
  }
  const results: AutocompleteItem[] = [];
  values.forEach(({ name, description, relevance }) => {
    if (name.startsWith(query)) {
      results.push({ name, description, relevance });
    } else if (camelCase(name).startsWith(query)) {
      results.push({ name: camelCase(name), description, relevance });
    }
  });
  return results;
}

export function getValidPropertyValues(
  propertyName: string,
): AutocompleteItem[] | undefined {
  // support multiple casings
  const property = cssJson.properties.find(
    ({ name }) => camelCase(name) === camelCase(propertyName),
  );
  if (property === undefined || property.values === undefined) {
    return undefined;
  }
  return (property.values as any).map((value: any) => ({
    name: value.name,
    description: value.description,
    relevance: value.relevance,
  }));
}

export function isValidPropertyValue(
  propertyName: string,
  propertyValue: string,
): boolean {
  const values = getValidPropertyValues(propertyName)?.map(
    (property) => property.name,
  );
  if (values === undefined) {
    return false;
  }
  return values.includes(propertyValue);
}
