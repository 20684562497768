"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styled = void 0;
const react_1 = require("@stitches/react");
const config = react_1.createCss({
    insertionMethod() {
        let currentCssHead;
        let currentCssNode;
        return (cssText) => {
            if (typeof document === 'object') {
                if (!currentCssHead) {
                    currentCssHead = document.head || document.documentElement;
                }
                if (!currentCssNode) {
                    currentCssNode =
                        document.getElementById('stitches-primitives') ||
                            Object.assign(document.createElement('style'), {
                                id: 'stitches-primitives',
                            });
                }
                if (!currentCssNode.parentNode) {
                    currentCssHead.append(currentCssNode);
                }
                currentCssNode.textContent = cssText;
            }
        };
    },
});
exports.styled = config.styled;
