import { MetadataContext } from '@symaphore/react-renderer';
import React, { useContext } from 'react';

import { styled } from '../stitches.config';

export function Fallback({ children }: React.PropsWithChildren<{}>) {
  const { instanceName } = useContext(MetadataContext);
  return (
    <FallbackWrapper>
      <FallbackLabel>{instanceName}</FallbackLabel>
      {children}
    </FallbackWrapper>
  );
}

export const FallbackLabel = styled('div', {
  color: '$blue',
  padding: '$1',
  position: 'absolute',
  top: 0,
  left: 0,
});

const FallbackWrapper = styled('div', {
  border: '.5px solid $blue',
  background: '$blue10',
  position: 'relative',
  padding: '$6',
});
