import { camelCase } from 'lodash';

import { AutocompleteItem } from './AutocompleteStyleMenu';

type DesignToken = {
  name: string;
  value: string;
};

// unnamed values, like color, length
export function completeDesignTokens(
  propertyName: string,
): Array<AutocompleteItem> {
  if (tokenProperties.hasOwnProperty(camelCase(propertyName))) {
    return tokens[tokenProperties[propertyName]];
  }
  return [];
}

// stored in camelCase as that's how we compare property names
export const tokenProperties: Record<string, string> = {
  padding: 'length',
  paddingTop: 'length',
  paddingBottom: 'length',
  paddingRight: 'length',
  paddingLeft: 'length',
  borderRadius: 'length',
};

export const tokens: Record<string, Array<DesignToken>> = {
  length: [
    {
      name: 'Small',
      value: '.25rem',
    },

    {
      name: 'Medium',
      value: '.5rem',
    },

    {
      name: 'Large',
      value: '.75rem',
    },

    {
      name: 'Extra Large',
      value: '1rem',
    },
  ],
};
