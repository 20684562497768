import { motion, useMotionValue } from 'framer-motion';
import React, { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { Range } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import { styled } from '../../stitches.config';

type Props = {
  range?: Range;
  isOpen: boolean;
  position?: 'bottom' | 'right';
};

export const InlineMenu = ({
  range,
  isOpen,
  children,
  position = 'bottom',
}: React.PropsWithChildren<Props>) => {
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const editor = useSlate();

  // update menu position synchronously to prevent flicker when isOpen activates before position is updated
  useLayoutEffect(() => {
    if (
      !isOpen ||
      !editor.selection ||
      !Range.isCollapsed(editor.selection) // dont show when selecting text
    ) {
      return;
    }

    try {
      const domRange = ReactEditor.toDOMRange(
        editor,
        range ?? editor.selection,
      );
      const container = domRange.commonAncestorContainer;

      const rect = container.parentElement?.getBoundingClientRect();
      if (rect) {
        if (position === 'bottom') {
          x.set(rect.x);
          y.set(rect.y + rect.height);
        }
        if (position === 'right') {
          x.set(rect.x + rect.width);
          y.set(rect.y);
        }
      }
    } catch (error) {
      console.log(error);
    }
  });

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <StyledMenu
      style={{
        x,
        y,
      }}>
      {children}
    </StyledMenu>,
    document.body,
  );
};

const StyledMenu = styled(motion.ul, {
  position: 'fixed',
  padding: 0,
  zIndex: '$dockedWindowOverlay',
});

export const InlineMenuItem = styled('li', {
  padding: '$1',
  listStyleType: 'none',

  variants: {
    isSelected: {
      true: {
        background: '$blue10',
        color: '$blue',
      },
    },
  },
});
