"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollapsibleButton = exports.Details = exports.Accordion = exports.Collapsible = void 0;
const PrimitiveCollapsible = __importStar(require("@radix-ui/react-collapsible"));
const react_1 = __importStar(require("react"));
const react_feather_1 = require("react-feather");
const stitches_config_1 = require("../stitches.config");
const alias_1 = require("../utils/alias");
const componentize_1 = require("../utils/componentize");
const displayName_1 = require("../utils/displayName");
const pipe_1 = require("../utils/pipe");
const spread_1 = require("../utils/spread");
const Box_1 = require("./Box");
const Button_1 = require("./Button");
exports.Collapsible = pipe_1.pipe(displayName_1.displayName('Collapsible'), spread_1.spread('props'), alias_1.alias([
    { prop: 'button', alias: 'trigger' },
    { prop: 'closeButton', alias: 'closeTrigger' },
    { prop: 'openButton', alias: 'openTrigger' },
    { prop: 'children', alias: 'content' },
]))(({ button = react_1.default.createElement(exports.CollapsibleButton, null, "Collapsible"), children, closeButton = button, openButton = button, }) => {
    const [open, onOpenChange] = react_1.useState(false);
    return (react_1.default.createElement(PrimitiveCollapsible.Root, { open: open, onOpenChange: onOpenChange },
        react_1.default.createElement(PrimitiveCollapsible.Button, { as: componentize_1.componentize(open ? closeButton : openButton, Box_1.Box) }),
        react_1.default.createElement(PrimitiveCollapsible.Content, { style: { display: 'contents' } }, children)));
});
exports.Accordion = exports.Collapsible;
exports.Details = exports.Collapsible;
exports.CollapsibleButton = react_1.default.forwardRef((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(StyledButton, Object.assign({}, props, { ref: ref }),
        react_1.default.createElement(react_feather_1.Play, { size: "1em" }),
        children));
});
const StyledButton = stitches_config_1.styled(Button_1.Button, {
    '&[data-state="open"]': {
        [`& > svg`]: {
            transform: 'rotate(90deg)',
        },
    },
});
