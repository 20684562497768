import { MotionValue, useMotionValue } from 'framer-motion';
import { useEffect } from 'react';

export type Point = {
  x: number;
  y: number;
};

export type MotionPoint = {
  x: MotionValue;
  y: MotionValue;
};

// Get motion values to use for performant animations
export function useMotionPoint(point: Point): MotionPoint {
  const pointX = useMotionValue(point.x);
  const pointY = useMotionValue(point.y);

  // Mirror stateful changes to point
  useEffect(() => {
    pointX.set(point.x);
    pointY.set(point.y);
  }, [point, pointX, pointY]);

  return {
    x: pointX,
    y: pointY,
  } as const;
}

function isMotionPoint(point: Point | MotionPoint): point is MotionPoint {
  return (point as MotionPoint).x.get !== undefined;
}

export function MotionPointToPoint(point: MotionPoint) {
  return {
    x: point.x.get(),
    y: point.y.get(),
  };
}

export function addPoints(a: Point | MotionPoint, b: Point | MotionPoint) {
  const a2 = isMotionPoint(a) ? MotionPointToPoint(a) : a;
  const b2 = isMotionPoint(b) ? MotionPointToPoint(b) : b;
  return {
    x: a2.x + b2.x,
    y: a2.y + b2.y,
  };
}

export function setMotionPoint(a: MotionPoint, b: Point | MotionPoint) {
  const b2 = isMotionPoint(b) ? MotionPointToPoint(b) : b;
  a.x.set(b2.x);
  a.y.set(b2.y);
}

export function subtractPoints(a: Point | MotionPoint, b: Point | MotionPoint) {
  const a2 = isMotionPoint(a) ? MotionPointToPoint(a) : a;
  const b2 = isMotionPoint(b) ? MotionPointToPoint(b) : b;
  return {
    x: a2.x - b2.x,
    y: a2.y - b2.y,
  };
}
