"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.styled = void 0;
const react_1 = __importStar(require("react"));
const stitches_config_1 = require("../stitches.config");
const as_1 = require("./as");
// HOC version of stitches `styled` function which forwards `as` through to the
// base component instead of the styled component. This allows using `as` when
// styling a Radix primitive, since the ordinary `as` prop on a styled component
// would replace the Radix primitive itself.
const styled = (styles) => (Component) => {
    const WrappedComponent = react_1.default.forwardRef((_a, ref) => {
        var { as: asValue } = _a, props = __rest(_a, ["as"]);
        const StyledComponent = react_1.useMemo(() => stitches_config_1.styled(asValue ? as_1.as(asValue)(Component) : Component, styles), [asValue]);
        return react_1.default.createElement(StyledComponent, Object.assign({}, props, { ref: ref }));
    });
    WrappedComponent.displayName = `styled(${Component.displayName || Component.name || 'Anonymous'})`;
    return WrappedComponent;
};
exports.styled = styled;
