import { atom } from 'jotai';
import { atomWithImmer } from 'jotai/immer';
import { Node } from 'slate';
import { v4 as uuid } from 'uuid';

import { Document, Window, Workspace } from '../types';

const STORAGE_KEY = 'WORKSPACE';

const testData: Record<string, Workspace> = {
  '1': {
    id: '1',
    title: 'CoolApp',
    windows: {
      '1ar': {
        id: '1ar',
        position: {
          x: 540,
          y: 100,
        },
        size: {
          x: 320,
          y: 0,
        },
        documentId: 'a',
        type: 'RENDERER',
      },
      '1br': {
        id: '1br',
        position: {
          x: 1080,
          y: 700,
        },
        size: {
          x: 320,
          y: 0,
        },
        documentId: 'b',
        type: 'RENDERER',
      },
    },
    documents: {
      a: {
        id: 'a',
        title: 'Home',
        content: textToSlate(`App
  Card
    Heading1
      Button "Click me"
  HStack
    Tab
    Tab
    Tab

Button=Box
  "→"
  style:
    background: "rgba(2,99,46,1)"
    padding: ".5rem"
    borderRadius: ".5rem"
    display: "inline-block"
    cursor: "pointer"
    color: 'white'

        `),
      },
      b: {
        id: 'b',
        title: 'Components',
        content: textToSlate(`Card

Heading1 "Heading"

Card=Box
  style:
    background: "hsl(147,35%,88%)"
    padding: "1rem"
    borderRadius: ".25rem"

Heading1=Box
  style:
    fontSize: "2rem"
    lineHeight: "100%"
    fontWeight: "bold"
    display: "block"
    textAlign: "center"
    marginBottom: ".25rem"
        `),
      },
    },
  },
  '2': {
    id: '2',
    title: 'FunApp',
    windows: {},
    documents: {},
  },
  '3': {
    id: '3',
    title: 'NeatApp',
    windows: {},
    documents: {},
  },
};

const getStorageOrTestData = () => {
  const existing = window.localStorage.getItem(STORAGE_KEY);
  if (existing) {
    const parsed: Record<string, Workspace> = JSON.parse(existing);
    return parsed;
  }
  return testData;
};

const coreDataAtom = atomWithImmer<Record<string, Workspace>>(
  getStorageOrTestData(),
);

// Writable atom override lets us save the data when any downstream writes happen
const writableDataAtom = atom(
  (get) => get(coreDataAtom),
  (_, set, update: (draft: Record<string, Workspace>) => void) =>
    set(coreDataAtom, (workspaces) => {
      update(workspaces);
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(workspaces));
    }),
);

export const selectedWorkspaceIdAtom = atom(
  Object.keys(getStorageOrTestData())[0],
);

export function createNewDocument(title: string) {
  const document: Document = {
    id: uuid(),
    title,
    content: textToSlate(''),
  };
  const DEFAULT_SIZE = {
    x: 320,
    y: 240,
  };
  const windows: Window[] = [
    {
      id: document.id + '-renderer',
      position: {
        x: 10,
        y: 10,
      },
      size: {
        x: DEFAULT_SIZE.x,
        y: 0,
      },
      documentId: document.id,
      type: 'RENDERER',
    },
  ];
  return [document, windows] as const;
}

export { writableDataAtom as workspacesAtom };

function textToSlate(str: string): Node[] {
  return str.split('\n').map((s) => {
    return {
      type: 'paragraph',
      children: [{ text: s }],
    };
  });
}
