import { useUpdateAtom } from 'jotai/utils';
import React, { useRef } from 'react';

import {
  addPoints,
  MotionPoint,
  MotionPointToPoint,
  setMotionPoint,
  subtractPoints,
} from '../../lib/point';
import useDrag from '../../lib/useDrag';
import { windowAtomFamily } from '../../state/atoms';
import { Resize } from './Styled';

enum Corner {
  NW = 'NW',
  NE = 'NE',
  SW = 'SW',
  SE = 'SE',
}

type ResizeProps = {
  id: string;
  position: MotionPoint;
  size: MotionPoint;
  disabled?: boolean;
};

export function ResizeCorners(props: ResizeProps) {
  return (
    <>
      {Object.keys(Corner).map((corner) =>
        props.disabled ? (
          <Resize key={corner} corner={corner as Corner} disabled />
        ) : (
          <ResizeCorner key={corner} corner={corner as Corner} {...props} />
        ),
      )}
    </>
  );
}

function ResizeCorner({
  id,
  position,
  size,
  corner,
}: ResizeProps & { corner: Corner }) {
  const [resizeRef] = useResizeWindow(id, position, size, corner);
  return <Resize corner={corner} ref={resizeRef} />;
}

function useResizeWindow(
  id: string,
  position: MotionPoint,
  size: MotionPoint,
  corner: Corner,
) {
  const draggableRef = useRef<HTMLDivElement>(null);
  const setWindow = useUpdateAtom(windowAtomFamily(id));
  const [isDragging] = useDrag(
    () =>
      setWindow((window) => {
        window.position = MotionPointToPoint(position);
        window.size = MotionPointToPoint(size);
      }),
    (offset) => {
      switch (corner) {
        case Corner.NW:
          setMotionPoint(position, addPoints(position, offset));
          setMotionPoint(size, subtractPoints(size, offset));
          break;
        case Corner.NE:
          position.y.set(position.y.get() + offset.y);
          size.x.set(size.x.get() + offset.x);
          size.y.set(size.y.get() - offset.y);
          break;
        case Corner.SW:
          position.x.set(position.x.get() + offset.x);
          size.x.set(size.x.get() - offset.x);
          size.y.set(size.y.get() + offset.y);
          break;
        case Corner.SE:
          setMotionPoint(size, addPoints(size, offset));
          break;
      }
    },
    draggableRef,
  );
  return [draggableRef, isDragging] as const;
}
