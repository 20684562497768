import React from 'react';
import { Editor, Node, Range, Text } from 'slate';

import { getLines } from './utils';

export function onCopy(e: React.ClipboardEvent, editor: Editor) {
  if (editor.selection) {
    e.clipboardData.setData(
      'text/plain',
      getTextInRange(editor.selection, editor.children),
    );
    e.preventDefault();
  }
}

function getTextInRange(range: Range, nodes: Node[]) {
  // go from anchor -> focus, for each path, assuming unnested nodes
  // if first or last, use the anchor/focus offset to grab substring
  const anchorLine = range.anchor.path[0];
  const focusLine = range.focus.path[0];
  const selectionText = getLines(range)
    .map((line) => {
      const text = (nodes[line].children as Text[])[0].text;
      switch (true) {
        case line === anchorLine:
          if (anchorLine < focusLine) {
            return textAfterOffset(text, range.anchor.offset);
          } else {
            return textBeforeOffset(text, range.anchor.offset);
          }
        case line === focusLine:
          if (focusLine < anchorLine) {
            return textAfterOffset(text, range.focus.offset);
          } else {
            return textBeforeOffset(text, range.focus.offset);
          }
        default:
          return text;
      }
    })
    .join('\n');
  return selectionText;
}

function textBeforeOffset(text: string, offset: number) {
  return text.substr(0, offset);
}

function textAfterOffset(text: string, offset: number) {
  return text.substr(offset, text.length - offset);
}
