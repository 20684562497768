"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
const as_1 = require("../utils/as");
const defaultProps_1 = require("../utils/defaultProps");
const displayName_1 = require("../utils/displayName");
const pipe_1 = require("../utils/pipe");
const styled_1 = require("../utils/styled");
const Box_1 = require("./Box");
exports.Button = pipe_1.pipe(displayName_1.displayName('Button'), as_1.as('button'), defaultProps_1.defaultProps({ children: ['Label'] }), styled_1.styled({
    alignItems: 'center',
    appearance: 'none',
    background: 'dodgerblue',
    border: 'none',
    borderRadius: 6,
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'system-ui',
    fontSize: 12,
    fontWeight: 600,
    gap: 4,
    justifyContent: 'center',
    padding: '6px 8px',
    transition: 'all 0.1s ease-in-out',
    '& *': {
        strokeWidth: '3px',
    },
    '&:hover': {
        filter: 'brightness(90%)',
    },
    '&:active': {
        filter: 'brightness(80%)',
    },
}))(Box_1.Row);
