import { useMotionValue } from 'framer-motion';
import React, { useEffect, useRef } from 'react';

import { Point } from './point';
import useStateRef from './useStateRef';

export default function useDrag(
  stopHandler: () => void,
  moveHandler: (offset: Point) => void,
  ref: React.MutableRefObject<HTMLDivElement | null>,
) {
  const [isDragging, setIsDragging, isDraggingRef] = useStateRef(false);
  const offsetX = useMotionValue(0);
  const offsetY = useMotionValue(0);
  const startRef = useRef<Point>();

  useEffect(() => {
    if (!ref.current || isDraggingRef.current === undefined) {
      return;
    }
    const el = ref.current;

    function start(e: MouseEvent) {
      if (isDraggingRef.current) {
        return;
      }
      setIsDragging(true);
      startRef.current = { x: e.pageX, y: e.pageY };
    }

    function stop() {
      if (!isDraggingRef.current) {
        return;
      }
      setIsDragging(false);
      stopHandler();
      offsetX.set(0);
      offsetY.set(0);
      startRef.current = undefined;
    }

    el.addEventListener('mousedown', start);
    document.addEventListener('mouseup', stop);

    function move(e: MouseEvent) {
      if (!isDraggingRef.current || !startRef.current) {
        return;
      }
      e.preventDefault(); // prevent text selection
      const prevOffsetX = offsetX.get();
      const prevOffsetY = offsetY.get();
      offsetX.set(e.pageX - startRef.current!.x);
      offsetY.set(e.pageY - startRef.current!.y);
      moveHandler({
        x: offsetX.get() - prevOffsetX,
        y: offsetY.get() - prevOffsetY,
      });
    }

    document.addEventListener('mousemove', move);

    return () => {
      el.removeEventListener('mousedown', start);
      document.removeEventListener('mouseup', stop);
      document.removeEventListener('mousemove', move);
    };
  }, [
    isDraggingRef,
    ref,
    setIsDragging,
    stopHandler,
    moveHandler,
    offsetX,
    offsetY,
    startRef,
  ]);

  return [isDragging, offsetX, offsetY] as const;
}
