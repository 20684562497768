import { useEffect, useRef, useState } from 'react';

// Mirrors a state to a ref which allows you to read a value in effects without excessive reruns
export default function useStateRef<T>(defaultValue: T) {
  const [value, set] = useState(defaultValue);
  const ref = useRef(defaultValue);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return [value, set, ref] as const;
}
