"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.displayName = void 0;
// HOC for setting a component's display name, this is mainly for combining with
// other HOCs when you may want to set a display name on the root component.
const displayName = (displayName) => (Component) => {
    Component.displayName = displayName;
    return Component;
};
exports.displayName = displayName;
