"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.as = void 0;
const defaultProps_1 = require("./defaultProps");
// HOC for passing a value to the `as` prop, mainly for combining with other
// HOCs. This is just a shorthand for `defaultProps({ as })` for ledgibility.
const as = (as) => (Component) => {
    const WrappedComponent = defaultProps_1.defaultProps({ as })(Component);
    WrappedComponent.displayName = `as(${Component.displayName || Component.name || 'Anonymous'})`;
    return WrappedComponent;
};
exports.as = as;
