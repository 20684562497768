"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilteringLexer = void 0;
const LexerIterator_1 = require("./LexerIterator");
class FilteringLexer {
    constructor({ lexer, ignoredTypes = [], allowOnce = () => [], }) {
        this.lexer = lexer;
        this.ignoredTypes = ignoredTypes;
        this.allowedTypes = new Set();
        this.allowOnce = allowOnce;
    }
    next() {
        const nextToken = this.lexer.next();
        this.allowOnce(nextToken).forEach((type) => this.allowedTypes.add(type));
        // Need a ! because includes is typed to only accept a string but works just
        // fine when passed undefined.
        if (!this.ignoredTypes.includes(nextToken === null || nextToken === void 0 ? void 0 : nextToken.type) ||
            this.allowedTypes.has(nextToken === null || nextToken === void 0 ? void 0 : nextToken.type)) {
            this.allowedTypes.delete(nextToken === null || nextToken === void 0 ? void 0 : nextToken.type);
            return nextToken;
        }
        else {
            return this.next();
        }
    }
    reset(chunk, state) {
        this.allowedTypes = new Set(state === null || state === void 0 ? void 0 : state.allowedTypes);
        this.lexer.reset(chunk, state);
        return this;
    }
    has(tokenType) {
        return this.lexer.has(tokenType);
    }
    formatError(token, message) {
        return this.lexer.formatError(token, message);
    }
    save() {
        return Object.assign(Object.assign({}, this.lexer.save()), { allowedTypes: Array.from(this.allowedTypes) });
    }
    pushState(state) {
        this.lexer.pushState(state);
    }
    popState() {
        this.lexer.popState();
    }
    setState(state) {
        this.lexer.setState(state);
    }
    [Symbol.iterator]() {
        return new LexerIterator_1.LexerIterator(this);
    }
}
exports.FilteringLexer = FilteringLexer;
