import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import React from 'react';

import {
  documentsAtom,
  isWorkspaceLoadedAtom,
  selectedWindowIdAtom,
  windowsAtom,
} from '../state/atoms';
import { global, styled } from '../stitches.config';
import { EditorWindow, RendererWindow } from './WorkspaceWindow';

const globalStyles = global({
  '*': { margin: 0 },
  iframe: { display: 'none' }, // hide thrown warning from error boundary
  body: {
    background: '$background',
  },
  '.visually-hidden': {
    position: 'absolute',
    top: -99999,
    left: -99999,
  },
});

export default function Workspace() {
  globalStyles();

  const isWorkspaceLoaded = useAtomValue(isWorkspaceLoadedAtom);

  if (!isWorkspaceLoaded) {
    return null;
  }

  return (
    <>
      <Canvas />
    </>
  );
}

function Canvas() {
  const documents = useAtomValue(documentsAtom);
  const windows = useAtomValue(windowsAtom);
  const setSelectedWindowId = useUpdateAtom(selectedWindowIdAtom);

  return (
    <Container>
      <Background onClick={() => setSelectedWindowId('')}></Background>
      {documents &&
        Object.values(documents).map((document) => (
          <EditorWindow key={document.id} document={document} />
        ))}
      {windows &&
        Object.values(windows).map((window) => (
          <RendererWindow
            key={window.id}
            window={window}
            document={documents[window.documentId]}
          />
        ))}
    </Container>
  );
}

const Container = styled('main', {
  position: 'absolute',
  top: 0,
  left: '$dockedEditorWidth',
  minWidth: '1000vw',
  minHeight: '1000vh',
});

const Background = styled('div', {
  full: 'fixed',
});
