"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
// Bypasses TS6133. Allow declared but unused functions.
// @ts-ignore
function id(d) { return d[0]; }
// Need to use require syntax because this isn't output at the top of the file
const { lexer } = require('./lexer');
const { combineRanges, last, rangeForToken } = require("@symaphore/utils");
;
;
;
;
const grammar = {
    Lexer: lexer,
    ParserRules: [
        { "name": "Program$ebnf$1", "symbols": [] },
        { "name": "Program$ebnf$1", "symbols": ["Program$ebnf$1", "Statement"], "postprocess": (d) => d[0].concat([d[1]]) },
        { "name": "Program", "symbols": ["Program$ebnf$1"], "postprocess": ([body]) => {
                return Object.assign({ type: 'Program', body }, combineRanges(body[0], last(body)));
            }
        },
        { "name": "Statement$subexpression$1", "symbols": ["ConstantDeclaration"] },
        { "name": "Statement$subexpression$1", "symbols": ["SymbolDeclaration"] },
        { "name": "Statement$subexpression$1", "symbols": ["SymbolInstanceExpression"] },
        { "name": "Statement", "symbols": ["Statement$subexpression$1"], "postprocess": data => data[0][0] },
        { "name": "ConstantDeclaration$subexpression$1", "symbols": ["Identifier"] },
        { "name": "ConstantDeclaration$subexpression$1", "symbols": ["MemberExpression"] },
        { "name": "ConstantDeclaration", "symbols": ["ConstantDeclaration$subexpression$1", { "literal": "=" }, "Expression"], "postprocess": ([[left], , right]) => (Object.assign({ type: 'ConstantDeclaration', left,
                right }, combineRanges(left, right)))
        },
        { "name": "SymbolDeclaration", "symbols": ["SymbolIdentifier", { "literal": "=" }, "SymbolInstanceExpression"], "postprocess": ([id, , body]) => (Object.assign({ type: 'SymbolDeclaration', id,
                body }, combineRanges(id, body)))
        },
        { "name": "SymbolInstanceExpression$ebnf$1", "symbols": ["InlineObjectExpression"], "postprocess": id },
        { "name": "SymbolInstanceExpression$ebnf$1", "symbols": [], "postprocess": () => null },
        { "name": "SymbolInstanceExpression$ebnf$2", "symbols": ["ObjectExpression"], "postprocess": id },
        { "name": "SymbolInstanceExpression$ebnf$2", "symbols": [], "postprocess": () => null },
        { "name": "SymbolInstanceExpression", "symbols": ["SymbolIdentifier", "SymbolInstanceExpression$ebnf$1", (lexer.has("newline") ? { type: "newline" } : newline), "SymbolInstanceExpression$ebnf$2"], "postprocess": ([id, inlineObjectExpression, , objectExpression]) => (Object.assign({ type: 'SymbolInstanceExpression', id, properties: [
                    ...((inlineObjectExpression === null || inlineObjectExpression === void 0 ? void 0 : inlineObjectExpression.properties) || []),
                    ...((objectExpression === null || objectExpression === void 0 ? void 0 : objectExpression.properties) || []),
                ] }, combineRanges(id, objectExpression || inlineObjectExpression || id)))
        },
        { "name": "SymbolIdentifier", "symbols": [(lexer.has("Identifier") ? { type: "Identifier" } : Identifier)], "postprocess": ([token]) => (Object.assign({ type: 'Identifier', name: token.value }, rangeForToken(token)))
        },
        { "name": "Identifier", "symbols": [(lexer.has("identifier") ? { type: "identifier" } : identifier)], "postprocess": ([token]) => (Object.assign({ type: 'Identifier', name: token.value }, rangeForToken(token)))
        },
        { "name": "MemberExpression$subexpression$1", "symbols": ["Identifier"] },
        { "name": "MemberExpression$subexpression$1", "symbols": ["MemberExpression"] },
        { "name": "MemberExpression", "symbols": ["MemberExpression$subexpression$1", { "literal": "." }, "Identifier"], "postprocess": ([[object], _, property]) => (Object.assign({ type: 'MemberExpression', object,
                property }, combineRanges(object, property)))
        },
        { "name": "BooleanLiteral", "symbols": [(lexer.has("boolean") ? { type: "boolean" } : boolean)], "postprocess": ([token]) => (Object.assign({ type: 'BooleanLiteral', value: token.value === "true" || token.value === "yes" }, rangeForToken(token)))
        },
        { "name": "NumericLiteral", "symbols": [(lexer.has("number") ? { type: "number" } : number)], "postprocess": ([token]) => (Object.assign({ type: 'NumericLiteral', value: parseFloat(token.value) }, rangeForToken(token)))
        },
        { "name": "StringLiteral", "symbols": [(lexer.has("string") ? { type: "string" } : string)], "postprocess": ([token]) => (Object.assign({ type: 'StringLiteral', value: JSON.parse(`"${token.value.slice(1, -1).split('\\\'').join("'")}"`) }, rangeForToken(token)))
        },
        { "name": "ObjectExpression$ebnf$1", "symbols": ["ObjectProperty"] },
        { "name": "ObjectExpression$ebnf$1", "symbols": ["ObjectExpression$ebnf$1", "ObjectProperty"], "postprocess": (d) => d[0].concat([d[1]]) },
        { "name": "ObjectExpression", "symbols": [(lexer.has("indent") ? { type: "indent" } : indent), "ObjectExpression$ebnf$1", (lexer.has("dedent") ? { type: "dedent" } : dedent)], "postprocess": ([, properties]) => (Object.assign({ type: 'ObjectExpression', properties }, combineRanges(properties[0], last(properties))))
        },
        { "name": "UnindentedObjectExpression", "symbols": ["ObjectProperty", "ObjectExpression"], "postprocess": ([first, rest]) => (Object.assign({ type: 'ObjectExpression', unindented: true, properties: [first, ...((rest === null || rest === void 0 ? void 0 : rest.properties) || [])] }, combineRanges(first, last((rest === null || rest === void 0 ? void 0 : rest.properties) || []))))
        },
        { "name": "ObjectProperty$subexpression$1", "symbols": ["Identifier"] },
        { "name": "ObjectProperty$subexpression$1", "symbols": ["MemberExpression"] },
        { "name": "ObjectProperty$subexpression$2", "symbols": [{ "literal": ":" }] },
        { "name": "ObjectProperty$subexpression$2", "symbols": [{ "literal": "=" }] },
        { "name": "ObjectProperty", "symbols": ["ObjectProperty$subexpression$1", "ObjectProperty$subexpression$2", "Expression"], "postprocess": ([[key], , value]) => (Object.assign({ type: 'ObjectProperty', key,
                value }, combineRanges(key, value)))
        },
        { "name": "ObjectProperty$subexpression$3", "symbols": ["Identifier"] },
        { "name": "ObjectProperty$subexpression$3", "symbols": ["MemberExpression"] },
        { "name": "ObjectProperty", "symbols": ["ObjectProperty$subexpression$3", "ObjectExpression"], "postprocess": ([[key], value]) => (Object.assign({ type: 'ObjectProperty', key,
                value }, combineRanges(key, value)))
        },
        { "name": "ObjectProperty$subexpression$4", "symbols": ["Identifier"] },
        { "name": "ObjectProperty$subexpression$4", "symbols": ["MemberExpression"] },
        { "name": "ObjectProperty", "symbols": ["ObjectProperty$subexpression$4"], "postprocess": ([[key]]) => (Object.assign({ type: 'ObjectProperty', key, value: null }, combineRanges(key)))
        },
        { "name": "ObjectProperty$subexpression$5", "symbols": [{ "literal": "-" }] },
        { "name": "ObjectProperty$subexpression$5", "symbols": [{ "literal": "*" }] },
        { "name": "ObjectProperty", "symbols": ["ObjectProperty$subexpression$5", "UnindentedObjectExpression"], "postprocess": ([, value]) => (Object.assign({ type: 'ObjectProperty', key: null, value }, combineRanges(value, value)))
        },
        { "name": "ObjectProperty$ebnf$1$subexpression$1", "symbols": [{ "literal": "-" }] },
        { "name": "ObjectProperty$ebnf$1$subexpression$1", "symbols": [{ "literal": "*" }] },
        { "name": "ObjectProperty$ebnf$1", "symbols": ["ObjectProperty$ebnf$1$subexpression$1"], "postprocess": id },
        { "name": "ObjectProperty$ebnf$1", "symbols": [], "postprocess": () => null },
        { "name": "ObjectProperty$subexpression$6", "symbols": ["BooleanLiteral"] },
        { "name": "ObjectProperty$subexpression$6", "symbols": ["NumericLiteral"] },
        { "name": "ObjectProperty$subexpression$6", "symbols": ["StringLiteral"] },
        { "name": "ObjectProperty$subexpression$6", "symbols": ["StrictInlineObjectExpression"] },
        { "name": "ObjectProperty$subexpression$6", "symbols": ["IfExpression"] },
        { "name": "ObjectProperty$subexpression$6", "symbols": ["ForInExpression"] },
        { "name": "ObjectProperty$subexpression$6", "symbols": ["SymbolInstanceExpression"] },
        { "name": "ObjectProperty", "symbols": ["ObjectProperty$ebnf$1", "ObjectProperty$subexpression$6"], "postprocess": ([, [value]]) => (Object.assign({ type: 'ObjectProperty', key: null, value }, combineRanges(value, value)))
        },
        { "name": "InlineObjectExpression$ebnf$1", "symbols": [] },
        { "name": "InlineObjectExpression$ebnf$1$subexpression$1", "symbols": [{ "literal": "," }, "InlineObjectProperty"] },
        { "name": "InlineObjectExpression$ebnf$1", "symbols": ["InlineObjectExpression$ebnf$1", "InlineObjectExpression$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]]) },
        { "name": "InlineObjectExpression", "symbols": ["InlineObjectProperty", "InlineObjectExpression$ebnf$1"], "postprocess": ([first, rest]) => {
                const properties = [
                    first,
                    ...(rest || []).map(([, property]) => property),
                ];
                return Object.assign({ type: 'ObjectExpression', properties }, combineRanges(properties[0], last(properties)));
            }
        },
        { "name": "StrictInlineObjectExpression$ebnf$1", "symbols": [] },
        { "name": "StrictInlineObjectExpression$ebnf$1$subexpression$1", "symbols": [{ "literal": "," }, "InlineObjectProperty"] },
        { "name": "StrictInlineObjectExpression$ebnf$1", "symbols": ["StrictInlineObjectExpression$ebnf$1", "StrictInlineObjectExpression$ebnf$1$subexpression$1"], "postprocess": (d) => d[0].concat([d[1]]) },
        { "name": "StrictInlineObjectExpression", "symbols": ["InlineObjectProperty", { "literal": "," }, "InlineObjectProperty", "StrictInlineObjectExpression$ebnf$1"], "postprocess": ([first, , second, rest]) => {
                const properties = [
                    first,
                    second,
                    ...(rest || []).map(([, property]) => property),
                ];
                return Object.assign({ type: 'ObjectExpression', properties }, combineRanges(properties[0], last(properties)));
            }
        },
        { "name": "InlineObjectProperty$subexpression$1", "symbols": ["Identifier"] },
        { "name": "InlineObjectProperty$subexpression$1", "symbols": ["MemberExpression"] },
        { "name": "InlineObjectProperty$subexpression$2", "symbols": [{ "literal": ":" }] },
        { "name": "InlineObjectProperty$subexpression$2", "symbols": [{ "literal": "=" }] },
        { "name": "InlineObjectProperty", "symbols": ["InlineObjectProperty$subexpression$1", "InlineObjectProperty$subexpression$2", "InlineExpression"], "postprocess": ([[key], , value]) => (Object.assign({ type: 'ObjectProperty', key,
                value }, combineRanges(key, value)))
        },
        { "name": "InlineObjectProperty$subexpression$3", "symbols": ["Identifier"] },
        { "name": "InlineObjectProperty$subexpression$3", "symbols": ["MemberExpression"] },
        { "name": "InlineObjectProperty", "symbols": ["InlineObjectProperty$subexpression$3"], "postprocess": ([[key]]) => (Object.assign({ type: 'ObjectProperty', key, value: null }, combineRanges(key)))
        },
        { "name": "InlineObjectProperty$ebnf$1$subexpression$1", "symbols": [{ "literal": "-" }] },
        { "name": "InlineObjectProperty$ebnf$1$subexpression$1", "symbols": [{ "literal": "*" }] },
        { "name": "InlineObjectProperty$ebnf$1", "symbols": ["InlineObjectProperty$ebnf$1$subexpression$1"], "postprocess": id },
        { "name": "InlineObjectProperty$ebnf$1", "symbols": [], "postprocess": () => null },
        { "name": "InlineObjectProperty$subexpression$4", "symbols": ["BooleanLiteral"] },
        { "name": "InlineObjectProperty$subexpression$4", "symbols": ["NumericLiteral"] },
        { "name": "InlineObjectProperty$subexpression$4", "symbols": ["StringLiteral"] },
        { "name": "InlineObjectProperty", "symbols": ["InlineObjectProperty$ebnf$1", "InlineObjectProperty$subexpression$4"], "postprocess": ([, [value]]) => (Object.assign({ type: 'ObjectProperty', key: null, value }, combineRanges(value)))
        },
        { "name": "IfExpression$subexpression$1", "symbols": ["Identifier"] },
        { "name": "IfExpression$subexpression$1", "symbols": ["MemberExpression"] },
        { "name": "IfExpression$ebnf$1", "symbols": [{ "literal": "then" }], "postprocess": id },
        { "name": "IfExpression$ebnf$1", "symbols": [], "postprocess": () => null },
        { "name": "IfExpression$ebnf$2$subexpression$1$subexpression$1", "symbols": [{ "literal": "else" }] },
        { "name": "IfExpression$ebnf$2$subexpression$1$subexpression$1", "symbols": [{ "literal": "otherwise" }] },
        { "name": "IfExpression$ebnf$2$subexpression$1$subexpression$2$subexpression$1$ebnf$1", "symbols": [{ "literal": "then" }], "postprocess": id },
        { "name": "IfExpression$ebnf$2$subexpression$1$subexpression$2$subexpression$1$ebnf$1", "symbols": [], "postprocess": () => null },
        { "name": "IfExpression$ebnf$2$subexpression$1$subexpression$2$subexpression$1", "symbols": ["IfExpression$ebnf$2$subexpression$1$subexpression$2$subexpression$1$ebnf$1", "ObjectExpression"] },
        { "name": "IfExpression$ebnf$2$subexpression$1$subexpression$2", "symbols": ["IfExpression$ebnf$2$subexpression$1$subexpression$2$subexpression$1"] },
        { "name": "IfExpression$ebnf$2$subexpression$1$subexpression$2", "symbols": ["IfExpression"] },
        { "name": "IfExpression$ebnf$2$subexpression$1", "symbols": ["IfExpression$ebnf$2$subexpression$1$subexpression$1", "IfExpression$ebnf$2$subexpression$1$subexpression$2"] },
        { "name": "IfExpression$ebnf$2", "symbols": ["IfExpression$ebnf$2$subexpression$1"], "postprocess": id },
        { "name": "IfExpression$ebnf$2", "symbols": [], "postprocess": () => null },
        { "name": "IfExpression", "symbols": [{ "literal": "if" }, "IfExpression$subexpression$1", "IfExpression$ebnf$1", "ObjectExpression", "IfExpression$ebnf$2"], "postprocess": ([ifToken, [test], , consequent, rest]) => {
                let [, [alternate]] = rest || [null, []];
                alternate = Array.isArray(alternate) ? alternate[1] : alternate;
                return Object.assign({ type: 'IfExpression', test,
                    consequent,
                    alternate }, combineRanges(rangeForToken(ifToken), alternate || consequent));
            }
        },
        { "name": "ForInExpression$subexpression$1", "symbols": ["Identifier"] },
        { "name": "ForInExpression$subexpression$1", "symbols": ["MemberExpression"] },
        { "name": "ForInExpression", "symbols": [{ "literal": "for" }, "Identifier", { "literal": "in" }, "ForInExpression$subexpression$1", "ObjectExpression"], "postprocess": ([forToken, left, , [right], body]) => (Object.assign({ type: 'ForInExpression', left,
                right,
                body }, combineRanges(rangeForToken(forToken), body)))
        },
        { "name": "Expression$subexpression$1", "symbols": ["Identifier"] },
        { "name": "Expression$subexpression$1", "symbols": ["MemberExpression"] },
        { "name": "Expression$subexpression$1", "symbols": ["BooleanLiteral"] },
        { "name": "Expression$subexpression$1", "symbols": ["NumericLiteral"] },
        { "name": "Expression$subexpression$1", "symbols": ["StringLiteral"] },
        { "name": "Expression$subexpression$1", "symbols": ["ObjectExpression"] },
        { "name": "Expression$subexpression$1", "symbols": ["StrictInlineObjectExpression"] },
        { "name": "Expression$subexpression$1", "symbols": ["IfExpression"] },
        { "name": "Expression$subexpression$1", "symbols": ["ForInExpression"] },
        { "name": "Expression$subexpression$1", "symbols": ["SymbolInstanceExpression"] },
        { "name": "Expression", "symbols": ["Expression$subexpression$1"], "postprocess": data => data[0][0] },
        { "name": "InlineExpression$subexpression$1", "symbols": ["Identifier"] },
        { "name": "InlineExpression$subexpression$1", "symbols": ["MemberExpression"] },
        { "name": "InlineExpression$subexpression$1", "symbols": ["BooleanLiteral"] },
        { "name": "InlineExpression$subexpression$1", "symbols": ["NumericLiteral"] },
        { "name": "InlineExpression$subexpression$1", "symbols": ["StringLiteral"] },
        { "name": "InlineExpression", "symbols": ["InlineExpression$subexpression$1"], "postprocess": data => data[0][0] }
    ],
    ParserStart: "Program",
};
exports.default = grammar;
