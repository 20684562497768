import createCss from '@stitches/react';

export const { styled, css, global } = createCss({
  theme: {
    space: {
      1: '.25rem',
      2: '.5rem',
      3: '.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      sidebarW: '16vw',
      dockedEditorWidth: '$sizes$dockedEditorWidth',
    },
    sizes: {
      dockedEditorWidth: '25vw',
    },
    radii: {
      1: '.25rem',
      2: '.5rem',
      circle: '50%',
    },
    colors: {
      beige: 'hsl(42,87%,97%)',
      beigeDark: 'hsl(42,84%,90%)',
      beigeDarker: 'hsl(42,81%,83%)',
      background: '$beige',
      blue: 'hsl(216,81%,51%)',
      blue10: 'hsla(216,81%,51%, .1)',
      rust: 'hsl(32,100%,42%)',
      grey10: 'hsla(39,12%,76%, .1)',
      grey50: 'hsla(39,12%,76%, .5)',
      grey100: 'hsla(39,12%,76%, 1)',
      border: '$grey100',
    },
    zIndices: {
      navigation: '1',
      dockedWindow: '10',
      dockedWindowSelected: '11',
      dockedWindowOverlay: '12',
    },
    shadows: {
      window: '0px 4px 48px 0px rgba(188,118,67,0.1)',
    },
  },
  utils: {
    full: (config) => (position) => ({
      position: position || '',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }),
    card: (config) => (background) => ({
      padding: '$2',
      borderRadius: '$2',
      background: background,
      boxShadow: '$window',
      border: '1px solid $grey50',
    }),
  },
});
