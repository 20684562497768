import { motion } from 'framer-motion';

import { styled } from '../../stitches.config';

export const Title = styled('div', {
  padding: '$1 0',
  position: 'relative',
  display: 'grid',
  placeItems: 'center',
  borderBottom: '.5px solid $border',
  cursor: 'grab',

  variants: {
    isTransparent: {
      true: {
        borderBottom: 'none',
        opacity: 0,
      },
    },
  },
});

export const TitleActions = styled('div', {
  position: 'absolute',
  top: 0,
  bottom: 0,
  display: 'grid',
  gridAutoFlow: 'column',
  placeContent: 'center',
  padding: '0 $1',

  variants: {
    side: {
      left: { left: 0 },
      right: {
        right: 0,
      },
    },
  },
});

export const TitleButton = styled('button', {
  appearance: 'none',
  border: 0,
  color: '$grey100',
  background: 'none',
  borderRadius: '$1',
  outline: 'none',

  '&:hover': {
    color: '$blue',
  },
  '&:active': {
    background: '$grey10',
  },
  '&:focus': {
    background: '$grey10',
  },
});

export const Content = styled('div', {
  padding: '$4',
  overflowY: 'auto',
});

export const Chrome = styled(motion.div, {
  position: 'absolute',
  background: 'white',
  borderRadius: '$2',
  overflow: 'hidden',
  boxShadow: '$window',
  border: '.5px solid $border',
  display: 'grid',
  gridTemplateRows: 'auto 1fr',

  variants: {
    isTransparent: {
      true: {
        background: 'none',
        borderColor: 'transparent',
        boxShadow: 'none',

        [`& ${Content}`]: {
          cursor: 'grab',
        },

        '&:hover': {
          borderColor: '$border',
          [`& ${Title}`]: {
            opacity: 1,
          },
        },
      },
    },
    isSelected: {
      true: {
        borderColor: '$blue',
        '&:hover': {
          borderColor: '$blue',
        },
      },
    },
    isDocked: {
      true: {
        position: 'fixed',
        width: '$dockedEditorWidth',
        height: 'calc(100% - $space$2 - $space$2)', // explicit height is needed to activate overflow scroll on children
      },
    },
    docked: {
      0: {
        left: '$2',
        top: '$2',
      },
      1: {
        right: '$2',
        top: '$2',
      },
    },
  },
});

export const Resize = styled('div', {
  width: '$space$4',
  height: '$space$4',
  position: 'absolute',

  variants: {
    corner: {
      NW: {
        top: 0,
        left: 0,
        cursor: 'nwse-resize',
      },
      NE: {
        top: 0,
        right: 0,
        cursor: 'nesw-resize',
      },
      SW: {
        bottom: 0,
        left: 0,
        cursor: 'nesw-resize',
      },
      SE: {
        bottom: 0,
        right: 0,
        cursor: 'nwse-resize',
      },
    },
    disabled: {
      true: {
        cursor: 'not-allowed !important',
      },
    },
  },
});
