import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

import { Document, Window, Workspace } from '../types';
import { selectedWorkspaceIdAtom, workspacesAtom } from './data';
export { selectedWorkspaceIdAtom, workspacesAtom };

export const workspaceAtom = atom(
  (get) => get(workspacesAtom)[get(selectedWorkspaceIdAtom)],
  (get, set, update: (draft: Workspace) => void) =>
    set(workspacesAtom, (workspaces) =>
      update(workspaces[get(selectedWorkspaceIdAtom)]),
    ),
);

export const isWorkspaceLoadedAtom = atom(
  (get) => get(workspaceAtom) !== undefined,
);

export const selectedWindowIdAtom = atom('');
export const selectedWindowAtom = atom(
  (get) => get(windowsAtom)[get(selectedWindowIdAtom)],
);

export const selectedDocumentIdAtom = atom(
  (get) => get(selectedWindowAtom)?.documentId ?? '',
);

export const windowsAtom = atom(
  (get) => get(workspaceAtom).windows,
  (_, set, update: (draft: Record<string, Window>) => void) =>
    set(workspaceAtom, (workspace) => update(workspace.windows)),
);

export const windowAtomFamily = atomFamily((id: string) =>
  atom(
    (get) => get(windowsAtom)[id],
    (_, set, update: (draft: Window) => void) =>
      set(windowsAtom, (windows) => update(windows[id])),
  ),
);

export const documentsAtom = atom(
  (get) => get(workspaceAtom).documents,
  (get, set, update: (draft: Record<string, Document>) => void) =>
    set(workspaceAtom, (workspace) => update(workspace.documents)),
);

export const documentAtomFamily = atomFamily((id: string) =>
  atom(
    (get) => get(documentsAtom)[id],
    (_, set, update: (draft: Document) => void) =>
      set(documentsAtom, (documents) => update(documents[id])),
  ),
);
