import * as components from '@symaphore/primitives';
import { ComponentProvider, Renderer } from '@symaphore/react-renderer';
import React from 'react';

import { Fallback } from './Fallback';

type Props = {
  children?: string;
};

const SymaphoreRenderer = React.memo(({ children = '' }: Props) => (
  <ComponentProvider components={components as any} fallback={Fallback}>
    <SymaphoreRendererErrorBoundary>{children}</SymaphoreRendererErrorBoundary>
  </ComponentProvider>
));

export default SymaphoreRenderer;

type ErrorBoundaryProps = {
  children: string;
};

type State = {
  didCatch: boolean;
  prevChildren: string;
};
class SymaphoreRendererErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  State
> {
  state = {
    didCatch: false,
    prevChildren: '',
  };

  componentDidCatch() {
    this.setState({ didCatch: true });
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps, prevState: State) {
    if (prevProps.children !== this.props.children) {
      this.setState({
        didCatch: false,
        prevChildren: !prevState.didCatch
          ? prevProps.children
          : prevState.prevChildren,
      });
    }
  }

  render() {
    return (
      <div style={{ opacity: this.state.didCatch ? '0.5' : 1 }}>
        {this.state.didCatch ? (
          <Renderer>{this.state.prevChildren}</Renderer>
        ) : (
          <Renderer>{this.props.children}</Renderer>
        )}
      </div>
    );
  }
}
