import * as Composition from '@symaphore/composition';
import {
  Identifier,
  ObjectExpression,
  ObjectProperty,
  Statement,
} from '@symaphore/composition';
import { camelCase, isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Editor, Range, Transforms } from 'slate';
import { useSlate } from 'slate-react';

import { styled } from '../../stitches.config';
import { getFlatOffset, getLeadingSpaces, getParentPath } from '../utils';
import { completePropertyName, completePropertyValue } from './CSSData';
import { completeDesignTokens } from './DesignTokens';
import { InlineMenu, InlineMenuItem } from './InlineMenu';

export type AutocompleteItem = {
  name: string;
  description?: string;
  relevance?: number;
  value?: string;
};

type Props = {
  content: string;
};

export const AutocompleteStyleMenu = ({ content }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const editor = useSlate();
  const [items, setItems] = useState<Array<AutocompleteItem>>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [query, setQuery] = useState('');
  const [isFocusedInValue, setIsFocusedInValue] = useState(false);

  // user can manually close menu, in which case we prevent automatic menu display
  const [isManuallyClosed, setIsManuallyClosed] = useState(false);

  // reset manual closing whenever selection changes
  useEffect(() => {
    setIsManuallyClosed(false);
  }, [editor.selection]);

  const replaceValue = useCallback(
    (search: string, replaceWith: string) => {
      const sel = editor.selection!;
      const currentLine = Editor.string(editor, sel.focus.path);
      const startOffset =
        search.length === 0 ? sel.focus.offset : currentLine.indexOf(search);
      const endOffset = startOffset + search.length;
      Transforms.insertText(editor, replaceWith, {
        at: {
          anchor: {
            path: sel.focus.path,
            offset: startOffset,
          },
          focus: {
            path: sel.focus.path,
            offset: endOffset,
          },
        },
      });
    },
    [editor],
  );

  const autocomplete = useCallback(
    (index: number) => {
      const selectedItem = items[index];
      // if it has a value it's a design token and we should use that instead of the name to fill
      const result = selectedItem.value ?? selectedItem.name;
      // replace the query with the result, no matter where you're focused in the query
      replaceValue(query, result);
    },
    [items, query, replaceValue],
  );

  const closeMenu = useCallback(() => {
    setIsOpen(false);
    setItems((prev) => (prev.length === 0 ? prev : []));
    setQuery('');
    setSelectedIndex(0);
    removeKeyboardEventHandlers(editor);
    setIsFocusedInValue(false);
  }, [editor]);

  // open menu if focus is inside a "style" property
  useEffect(() => {
    try {
      const autocompleted = getAutocompleteItems(editor, content);
      if (autocompleted) {
        setItems((prev) =>
          isEqual(prev, autocompleted.items) ? prev : autocompleted.items,
        );
        setQuery(autocompleted.query);
        setIsOpen(true);
        setIsFocusedInValue(autocompleted.focus === 'value');

        addKeyboardEventHandlers(editor, {
          onUp: () => {
            setSelectedIndex(
              (prev) =>
                (prev - 1 + autocompleted.items.length) %
                autocompleted.items.length,
            );
          },
          onDown: () => {
            setSelectedIndex((prev) => (prev + 1) % autocompleted.items.length);
          },
          onEnter: () => {
            autocomplete(selectedIndex);
            closeMenu();
          },
          onEscape: () => {
            closeMenu();
            setIsManuallyClosed(true);
          },
        });
      } else {
        closeMenu();
      }
    } catch (error) {
      console.log('Parsing error autocomplete menu, aborting');
    }
  }, [
    closeMenu,
    content,
    editor,
    editor.selection,
    autocomplete,
    selectedIndex,
  ]);

  const isQueryAutocompleted = !!items.find(
    (item) => item.name === query || item.value === query,
  );

  return (
    <>
      <InlineMenu
        isOpen={isQueryAutocompleted && isFocusedInValue}
        position="right">
        <StyledButton
          onPointerDown={(e) => {
            // FIXME: what if instead of adding this state, we just simply replace the query in the editor content with empty string
            // that would trigger the menu open and also show all potential choices instead of just the filtered ones
            // FIXME: tried it, works for values, but not for property, obviously
            // should we even show this for property?
            replaceValue(query, '');
            // setIsManuallyOpen(true);
            // prevent unfocusing editor
            e.preventDefault();
            e.stopPropagation();
          }}>
          ✏
        </StyledButton>
      </InlineMenu>
      <InlineMenu isOpen={isOpen && !isQueryAutocompleted && !isManuallyClosed}>
        <StyledMenu>
          {items
            .sort(
              ({ relevance: aRelevance = 0 }, { relevance: bRelevance = 0 }) =>
                bRelevance - aRelevance,
            )
            .map((item, i) => (
              <InlineMenuItem
                key={i}
                isSelected={selectedIndex === i}
                style={{
                  cursor: 'pointer',
                }}
                onPointerOver={(e) => {
                  setSelectedIndex(i);
                }}
                onPointerDown={(e) => {
                  autocomplete(selectedIndex);
                  closeMenu();
                  // prevent unfocusing editor
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                {item.name}
              </InlineMenuItem>
            ))}
        </StyledMenu>
      </InlineMenu>
    </>
  );
};

const StyledButton = styled('div', {
  card: 'white',
  boxShadow: 'none',
  padding: '$1',
  marginLeft: '$1',
  height: '.75rem',
  display: 'grid',
  placeItems: 'center',
  lineHeight: '.75rem',
  color: '$grey100',
  cursor: 'pointer',

  '&:hover': {
    color: '$blue',
    background: '$blue10',
  },
});
const StyledMenu = styled('div', {
  width: '10rem',
  card: 'white',
  padding: 0,
  maxHeight: '40vh',
  overflowY: 'auto',
});

function offsetInRange(offset: number, anchor: number, focus: number) {
  return anchor < offset && focus >= offset;
}

// FIXME: use proper visitor() function to walk and support each type
function getStyleProperty(
  statement: Statement,
  offset: number,
): ObjectProperty | undefined {
  if (offsetInRange(offset, statement.anchor.offset, statement.focus.offset)) {
    if (statement.type === 'SymbolInstanceExpression') {
      // see if we're in this symbol instance's styles
      const styleProperty = statement.properties.find((property) =>
        isStyleProperty(property, offset),
      );
      if (styleProperty) {
        return styleProperty;
      }

      // check this symbol instance's children symbol instances
      return statement.properties
        .map((property) => {
          if (property.value?.type === 'SymbolInstanceExpression') {
            return getStyleProperty(property.value, offset);
          }
          return undefined;
        })
        .find((property) => property !== undefined);
    } else if (statement.type === 'SymbolDeclaration') {
      return getStyleProperty(statement.body, offset);
    }
  }
}

function isStyleProperty(property: ObjectProperty, searchOffset: number) {
  if (
    property.anchor.offset === searchOffset &&
    property.key?.type === 'Identifier' &&
    isStyleVariantProperty(property.key.name)
  ) {
    return true;
  }
}

function isStyleVariantProperty(name: string) {
  // property name can be camel, kebab, space case
  const anyCaseName = camelCase(name);
  return (
    anyCaseName === 'style' ||
    anyCaseName === 'hoverStyle' ||
    anyCaseName === 'activeStyle'
  );
}

type OnKeyDownAutocomplete = {
  onUp: () => void;
  onDown: () => void;
  onEnter: () => void;
  onEscape: () => void;
};

function addKeyboardEventHandlers(
  editor: Editor,
  { onUp, onDown, onEnter, onEscape }: OnKeyDownAutocomplete,
) {
  // Attach our own event handler for onKeyDown that we call from slate/onKeyDown.ts
  editor.onKeyDownAutocomplete = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowDown':
        onDown();
        e.preventDefault();
        break;
      case 'ArrowUp':
        onUp();
        e.preventDefault();
        break;
      case 'Enter':
        onEnter();
        e.preventDefault();
        break;
      case 'Escape':
        onEscape();
        e.preventDefault();
        break;
    }
  };
}

function removeKeyboardEventHandlers(editor: Editor) {
  editor.onKeyDownAutocomplete = undefined;
}

type AutocompleteState = {
  items: Array<AutocompleteItem>;
  query: string;
  focus: 'key' | 'value' | undefined;
};

function getAutocompleteItems(
  editor: Editor,
  content: string,
): AutocompleteState | undefined {
  if (!editor.selection || !Range.isCollapsed(editor.selection)) {
    return;
  }

  const parentPath = getParentPath(editor, editor.selection.focus.path);
  // no autocomplete because styles cannot be orphaned
  if (!parentPath) {
    return;
  }

  // convert path to offset that Composition understands, which treats all content as one string and offsets from [0,0]
  // FIXME: change Slate data structure to be similar?
  const parentOffset = getFlatOffset(
    editor,
    parentPath,
    getLeadingSpaces(Editor.string(editor, parentPath)),
  );

  const program = Composition.parse(content);

  // get the style object we're currently focused in
  let styleObjectProperty;
  for (let i = 0; i < program.body.length; i++) {
    const prop = getStyleProperty(program.body[i], parentOffset);
    if (prop) {
      styleObjectProperty = prop;
      break;
    }
  }

  if (!styleObjectProperty) {
    return;
  }

  const selectionOffset = getFlatOffset(
    editor,
    editor.selection.focus.path,
    editor.selection.focus.offset,
  );

  // go through each key/value pair and extract the one we're focused on
  const styles = (styleObjectProperty.value as ObjectExpression).properties;
  let currentStyle;
  let isInKey = false;
  let isInValue = false;
  for (let i = 0; i < styles.length; i++) {
    const style = styles[i];
    const key = style.key;
    if (
      key &&
      offsetInRange(selectionOffset, key.anchor.offset, key.focus.offset)
    ) {
      currentStyle = style;
      isInKey = true;
    }
    const value = style.value;
    if (
      value &&
      offsetInRange(
        selectionOffset,
        value.anchor.offset,
        value.type === 'StringLiteral'
          ? value.focus.offset - 1
          : value.focus.offset, // dont show menu after end quote for strings
      )
    ) {
      currentStyle = style;
      isInValue = true;
    }
  }

  if (!currentStyle) {
    return;
  }

  let autocompleteItems: Array<AutocompleteItem> = [];
  let autocompleteQuery = '';
  const key = (currentStyle.key as Identifier).name;
  if (isInKey) {
    autocompleteItems = completePropertyName(key);
    autocompleteQuery = key;
  } else if (isInValue && key && currentStyle.value) {
    // autocomplete strings or identifiers (strings without quotes)
    let value;
    if (currentStyle.value.type === 'StringLiteral') {
      value = currentStyle.value.value;
    } else if (currentStyle.value.type === 'Identifier') {
      value = currentStyle.value.name;
    }
    if (value !== undefined) {
      autocompleteItems = completePropertyValue(value, key);
      autocompleteQuery = value;
      if (autocompleteItems.length === 0) {
        autocompleteItems = completeDesignTokens(key);
        console.log(autocompleteItems);
      }
    }
  }

  if (autocompleteItems.length > 0) {
    return {
      items: autocompleteItems,
      query: autocompleteQuery,
      focus: isInKey ? 'key' : isInValue ? 'value' : undefined,
    };
  }
}
