"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.I = exports.EM = exports.Oblique = exports.Emphasized = exports.Italic = exports.B = exports.Strong = exports.Bold = exports.Callout = exports.Quotation = exports.BlockQuote = exports.Quote = exports.A = exports.Anchor = exports.Link = exports.H6 = exports.Heading6 = exports.H5 = exports.Heading5 = exports.H4 = exports.Heading4 = exports.H3 = exports.Heading3 = exports.H2 = exports.Heading2 = exports.H1 = exports.Heading1 = exports.P = exports.Paragraph = exports.Text = void 0;
const stitches_config_1 = require("../stitches.config");
const as_1 = require("../utils/as");
const Box_1 = require("./Box");
exports.Text = stitches_config_1.styled(as_1.as('p')(Box_1.Box), {
    display: 'initial',
    fontFamily: 'system-ui',
    position: 'relative',
});
exports.Paragraph = exports.Text;
exports.P = exports.Text;
exports.Heading1 = as_1.as('h1')(exports.Text);
exports.H1 = exports.Heading1;
exports.Heading2 = as_1.as('h2')(exports.Text);
exports.H2 = exports.Heading2;
exports.Heading3 = as_1.as('h3')(exports.Text);
exports.H3 = exports.Heading3;
exports.Heading4 = as_1.as('h4')(exports.Text);
exports.H4 = exports.Heading4;
exports.Heading5 = as_1.as('h5')(exports.Text);
exports.H5 = exports.Heading5;
exports.Heading6 = as_1.as('h6')(exports.Text);
exports.H6 = exports.Heading6;
exports.Link = as_1.as('a')(exports.Text);
exports.Anchor = exports.Link;
exports.A = exports.Link;
exports.Quote = as_1.as('blockquote')(exports.Text);
exports.BlockQuote = exports.Quote;
exports.Quotation = exports.Quote;
exports.Callout = exports.Quote;
exports.Bold = as_1.as('b')(exports.Text);
exports.Strong = exports.Bold;
exports.B = exports.Bold;
exports.Italic = as_1.as('i')(exports.Text);
exports.Emphasized = exports.Italic;
exports.Oblique = exports.Italic;
exports.EM = exports.Italic;
exports.I = exports.Italic;
