"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexerIterator = void 0;
class LexerIterator {
    constructor(lexer) {
        this.lexer = lexer;
    }
    next() {
        // Need a ! because @types/moo expects an Iterator<Token> not the
        // correct Iterator<Token | undefined>, although this doesn't effect
        // anything functionally.
        const token = this.lexer.next();
        return { value: token, done: !token };
    }
    [Symbol.iterator]() {
        return this;
    }
}
exports.LexerIterator = LexerIterator;
