"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.combineRanges = exports.rangeForToken = void 0;
const rangeForToken = (token) => ({
    anchor: { offset: token.offset },
    focus: { offset: token.offset + token.value.length },
});
exports.rangeForToken = rangeForToken;
const combineRanges = (a, b) => ({
    anchor: { offset: (a === null || a === void 0 ? void 0 : a.anchor.offset) || 0 },
    focus: { offset: (b === null || b === void 0 ? void 0 : b.focus.offset) || (a === null || a === void 0 ? void 0 : a.anchor.offset) || 0 },
});
exports.combineRanges = combineRanges;
