import * as Dropdown from '@radix-ui/react-dropdown-menu';
import { atom, useAtom } from 'jotai';
import { atomFamily, useUpdateAtom } from 'jotai/utils';
import React, { useEffect, useState } from 'react';

import { serialize } from '../slate/utils';
import {
  documentAtomFamily,
  selectedWindowAtom,
  selectedWindowIdAtom,
  windowsAtom,
} from '../state/atoms';
import { Document, Window as WindowDataType } from '../types';
import Navigation from './Navigation';
import SymaphoreEditor from './SymaphoreEditor';
import SymaphoreRenderer from './SymaphoreRenderer';
import Window, { TitleButton, WindowType } from './Window';

const isSelectedFamilyAtom = atomFamily((id: string) =>
  atom(
    (get) => id === get(selectedWindowIdAtom),
    (_, set) => set(selectedWindowIdAtom, id),
  ),
);

type Props = {
  document: Document;
  window: WindowDataType;
};

export function RendererWindow({
  document: { title, content },
  window: { id, type, position, size },
}: Props) {
  const [isSelected, setIsSelected] = useAtom(isSelectedFamilyAtom(id));

  if (type === 'RENDERER') {
    return (
      <Window
        type={WindowType.CHROMELESS}
        id={id}
        title={title}
        position={position}
        size={size}
        isSelected={isSelected}
        select={() => !isSelected && setIsSelected()}>
        <SymaphoreRenderer>{serialize(content)}</SymaphoreRenderer>
      </Window>
    );
  }
  return null;
}

const isEditorSelectedFamilyAtom = atomFamily((id: string) =>
  atom(
    (get) => id === get(selectedWindowAtom)?.documentId,
    (get, set) =>
      set(
        selectedWindowIdAtom,
        Object.values(get(windowsAtom)).find(
          (window) => window.documentId === id,
        )!.id,
      ),
  ),
);

type EditorWindowProps = {
  document: Document;
};

export function EditorWindow({
  document: { id, title, content },
}: EditorWindowProps) {
  const updateDocument = useUpdateAtom(documentAtomFamily(id));
  const [isSelected, setIsSelected] = useAtom(isEditorSelectedFamilyAtom(id));
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setIsFocused(isSelected);
  }, [isSelected]);

  if (!isSelected) {
    return null;
  }

  return (
    <Window
      type={WindowType.DOCKED}
      title={title}
      isSelected={isSelected}
      select={() => setIsSelected(true)}
      actions={[
        <>
          <NavigationButton />
        </>,
      ]}>
      <SymaphoreEditor
        isFocused={isFocused}
        nodes={content}
        updateNodes={(nodes) =>
          updateDocument((document) => {
            document.content = nodes;
          })
        }
      />
    </Window>
  );
}

function NavigationButton() {
  return (
    <>
      <Dropdown.Root>
        <Dropdown.Trigger as={TitleButton}>Menu</Dropdown.Trigger>
        <Dropdown.Content disableOutsideScroll={false}>
          <Navigation />
        </Dropdown.Content>
      </Dropdown.Root>
    </>
  );
}
