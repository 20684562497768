"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pipe = void 0;
// HOC which combines together other HOCs and applies them in order from bottom
// to top, outermost to innermost.
const pipe = (...functions) => (Component) => {
    let WrappedComponent = Component;
    [...functions]
        .reverse()
        .forEach((f) => (WrappedComponent = f(WrappedComponent)));
    return WrappedComponent;
};
exports.pipe = pipe;
