import { Editor, Node, Path, Range } from 'slate';

// Turns Slate data structure into string
export const serialize = (value: Array<Node>) =>
  value.map((n) => Node.string(n)).join('\n');

// For a range return an array of line indices
// .e.g [3, 4, 5, 6] for lines 3 - 6
export function getLines(range: Range): number[] {
  // This code assumes no nesting of nodes, so only look at first part of path
  // assume only one child at index 0
  const firstLine = Range.start(range).path[0];
  const lastLine = Range.end(range).path[0];
  const totalLines = lastLine - firstLine + 1;
  return [...Array(totalLines)].map((_, i) => firstLine + i);
}

// Get the text at a path
export function getTextAtPath(editor: Editor, path: Path) {
  return Editor.string(editor, path);
  // assumes that the children of the node has a text attribute
  // return (editor.children[path[0]].children as Text[])[path[1]].text;
}

// returns the Path of the immediate parent of the current line
export function getParentPath(editor: Editor, startPath: Path) {
  const startIndent = getLeadingSpaces(Editor.string(editor, startPath));
  if (startIndent === 0) {
    return;
  }
  let line = startPath[0];
  // check each line above, return first one that's unindented above
  while (line >= 0) {
    const path: Path = [line, 0];
    const text = Editor.string(editor, path);
    if (getLeadingSpaces(text) < startIndent) {
      return path;
    }
    line--;
  }
}

// flattens a path + offset to an offset from path [0,0]
export function getFlatOffset(editor: Editor, path: Path, offset: number) {
  let totalOffset = offset;
  // add each line's length + 1 to represent newline
  for (let line = path[0] - 1; line >= 0; line--) {
    const path: Path = [line, 0];
    const text = Editor.string(editor, path);
    totalOffset += text.length + 1;
  }
  return totalOffset;
}

// indentation as spaces
export function getLeadingSpaces(text: string) {
  const indexOfFirstNonWhitespaceChar = text.search(/\S/);
  if (indexOfFirstNonWhitespaceChar === -1) {
    return text.length;
  }
  return indexOfFirstNonWhitespaceChar;
}

// 2 spaces to a tab
export function spacesToTabs(spaces: number) {
  return Math.abs(Math.round(spaces / 2));
}
