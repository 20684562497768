import React from 'react';
import { Editor, Range, Transforms } from 'slate';

import {
  getLeadingSpaces,
  getLines,
  getTextAtPath,
  spacesToTabs,
} from './utils';

export function onKeyDown(e: React.KeyboardEvent, editor: Editor) {
  if (editor.onKeyDownAutocomplete) {
    (editor as any).onKeyDownAutocomplete(e);
  }

  if (e.defaultPrevented) {
    return;
  }

  if (e.key === `'` || e.key === `"`) {
    if (editor.selection && Range.isExpanded(editor.selection)) {
      wrapKey(e, editor);
    } else {
      doubleKey(e, editor);
    }
  }
  if (e.key === 'Enter') {
    indentNewline(e, editor);
  }
  if (e.key === 'Tab') {
    if (e.shiftKey) {
      dedent(e, editor);
    } else {
      indent(e, editor);
    }
  }
  if (e.metaKey) {
    switch (e.key) {
      case 'g':
        groupLines(e, editor);
        break;
      case '[':
        dedent(e, editor);
        break;
      case ']':
        indent(e, editor);
        break;
    }
  }
}

function wrapKey(e: React.KeyboardEvent, editor: Editor) {
  const sel = editor.selection!;

  Transforms.insertText(editor, e.key, {
    at: Range.start(sel),
  });
  Transforms.insertText(editor, e.key, {
    at: {
      ...Range.end(sel),
      offset: Range.end(sel).offset + 1, // offset last point so we wrap 'asdf' instead of 'asd'f
    },
  });
  e.preventDefault();
}

// for '', "", etc keys that can help boost renderer perf when enclosed by reducing errors
function doubleKey(e: React.KeyboardEvent, editor: Editor) {
  const sel = editor.selection;
  if (!sel) {
    return;
  }
  const line = sel.focus.path[0];
  const offset = sel.focus.offset;
  const text = getTextAtPath(editor, [line, 0]);
  const keyAlreadyDoubled =
    text.charAt(offset) === e.key || text.charAt(offset - 1) === e.key;
  const oddKeyExistsInLine = text.split(e.key).length % 2 === 0;
  if (keyAlreadyDoubled || oddKeyExistsInLine) {
    return;
  }
  editor.insertText(e.key.repeat(2));
  Transforms.move(editor, {
    distance: 1,
    reverse: true,
  });
  e.preventDefault();
}

function indentNewline(e: React.KeyboardEvent, editor: Editor) {
  const sel = editor.selection;
  if (!sel) {
    return;
  }

  // if selecting a range of text, use the start (topmost line) as base
  const selStart = Range.start(sel);

  // if at the start of the line, no indent
  if (selStart.offset === 0) {
    return; // standard behavior
  }

  // whenever you hit return, check current line and insert same indent
  const lineNumber = selStart.path[0];
  const text = getTextAtPath(editor, [lineNumber, 0]);

  // if line is all whitespace, no indent
  if (text.replaceAll(' ', '').length === 0) {
    return;
  }

  // if line is indented, preserve indentation
  const tabLevel = spacesToTabs(getLeadingSpaces(text));

  // if line is unindented (tabLevel 0), assume user wants to indent one level
  const minimumTabLevel = 1;
  const indentTabs = '  '.repeat(Math.max(tabLevel, minimumTabLevel));

  // Remove selection if selection is expanded
  if (Range.isExpanded(sel)) {
    Transforms.delete(editor, { at: sel });
  }
  // Indent & split line
  Transforms.insertText(editor, indentTabs, {
    at: Range.start(sel),
  });
  Transforms.splitNodes(editor, {
    at: Range.start(sel),
  });
  e.preventDefault();
}

function indent(e: React.KeyboardEvent, editor: Editor) {
  const sel = editor.selection;
  if (!sel) {
    return;
  }
  // This code assumes no nesting of nodes, so only look at first part of path
  // assume second part is 0
  const rangeLines = [sel.anchor.path[0], sel.focus.path[0]].sort(
    (a, b) => a - b,
  );

  for (let i = rangeLines[0]; i <= rangeLines[1]; i++) {
    const text = getTextAtPath(editor, [i, 0]);
    const shouldFullIndent = isLeadingSpacesEven(text);
    // check indexOf first non space char to see if we should indent 2 or 1 spaces
    Transforms.insertText(editor, shouldFullIndent ? '  ' : ' ', {
      at: { path: [i, 0], offset: 0 },
    });
  }
  e.preventDefault();
}

function dedent(e: React.KeyboardEvent, editor: Editor) {
  const sel = editor.selection;
  if (!sel) {
    return;
  }
  // This code assumes no nesting of nodes, so only look at first part of path
  // assume second part is 0
  const rangeLines = [sel.anchor.path[0], sel.focus.path[0]].sort(
    (a, b) => a - b,
  );

  for (let i = rangeLines[0]; i <= rangeLines[1]; i++) {
    const text = getTextAtPath(editor, [i, 0]);
    const shouldFullIndent = isLeadingSpacesEven(text);

    // Unindent if line starts with 2 spaces
    if (text.indexOf('  ') === 0) {
      Transforms.delete(editor, {
        at: {
          anchor: { path: [i, 0], offset: 0 },
          focus: { path: [i, 0], offset: shouldFullIndent ? 2 : 1 },
        },
      });
    }
  }
  e.preventDefault();
}

function groupLines(e: React.KeyboardEvent, editor: Editor) {
  const sel = editor.selection;
  if (!sel) {
    return;
  }

  // use least indented of all lines
  const groupIndent = getLines(sel).reduce((min, line) => {
    const indent = getLeadingSpaces(getTextAtPath(editor, [line, 0]));
    return Math.min(min, indent);
  }, 99999);

  indent(e, editor);

  const groupPoint = {
    ...Range.start(sel),
    offset: 0,
  };
  const groupText = ' '.repeat(groupIndent) + 'Box';
  Transforms.insertNodes(
    editor,
    [
      {
        type: 'paragraph',
        children: [
          {
            text: groupText,
          },
        ],
      },
    ],
    {
      at: groupPoint,
    },
  );
  Transforms.select(editor, {
    anchor: {
      ...groupPoint,
      offset: groupIndent,
    },
    focus: {
      ...groupPoint,
      offset: groupText.length,
    },
  });
  e.preventDefault();
}

function isLeadingSpacesEven(text: string): boolean {
  return getLeadingSpaces(text) % 2 === 0;
}
